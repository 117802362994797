import { useMutation, useQueryClient } from "@tanstack/react-query";
import { TenantType, User } from "types";
import { api } from "lib";
import { useNotifications } from "components/organisms";

export const useEditRenter = () => {
  const qC = useQueryClient();
  const mutation = useMutation({
    mutationKey: ['updateTenant'],
    mutationFn: (renter: Partial<User>) => {
      let obj: any = {...renter};
      return api.patch('/property/renter', {body: JSON.stringify(obj)}, true, 'json');
    },
    onSuccess: (res: any, updatedRenter) => {
      qC.setQueryData(
        ['authenticated-user'],
        (oldData: TenantType) => {
          if (oldData){
            return {...oldData, ...updatedRenter}
          }
          return oldData
        }
      )      
      useNotifications.getState().addNotification({
        type: 'success',
        title: 'Success',
        message: `Successfully updated profile`,
      });
    },
    onError: (error) => {
      useNotifications.getState().addNotification({
        type: 'error',
        title: 'Failed to edit profile',
        message: error.message ? error.message : 'Try again',
      });
    }
  })
  return mutation;
}