import { useState } from 'react';
import { Swiper as SwiperLib, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import { Image, Grid, Hide } from "@chakra-ui/react";
import Lightbox from "yet-another-react-lightbox";
import Counter from "yet-another-react-lightbox/plugins/counter";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/counter.css";

type SwiperProps = {
  photoUrls: Array<any>,
  height: string|string[],
  border?: string,
  width?: string
}

export const Swiper = ({photoUrls, height, width='100%', border = '1px dashed rgba(0, 0, 0, 0.3)'}: SwiperProps) => {
  const [lightbox, setLightbox] = useState<boolean>(false)
  return (
    <Grid height={height} width={width} alignItems={'center'} borderRadius={'8px'} border={border}>
      <SwiperLib navigation={true} modules={[Navigation]} className="mySwiper">
        {photoUrls.map((val:any, i: number) => (
          <SwiperSlide key={i}>
            <Hide below="md">
              <Image onClick={() => setLightbox(true)} height={'100%'} objectFit={'cover'} src={val} />
            </Hide>
            <Hide above="md">
              <Image onClick={() => setLightbox(true)} height={'200px'} objectFit={'cover'} src={val} />
            </Hide>
          </SwiperSlide>
        ))} 
      </SwiperLib>
      <Lightbox
        plugins={[Counter]}
        open={lightbox}
        counter={{ container: { style: { top: "unset", bottom: 0 } } }}
        close={() => setLightbox(false)}
        slides={photoUrls.map(src => ({src}))}
      />
    </Grid>
  )
}
