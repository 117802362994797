import { AnyObject, array, lazy, mixed, number, NumberSchema, object, ObjectSchema, ref, string } from "yup"
import { StatusEnum } from "../utils";

export const SigninSchema = (): ObjectSchema<AnyObject> => {
  return object({
    email: string()
            .required('Required')
            .email('Invalid email'),
    password: string()
              .required('Required')
              .min(4, 'At least 6 characters')
              .max(24, 'At max 24 characters')
  });
}

export const SignupSchema = () => {
  return object({
    email:     
      string()
      .required('Required')
      .email('Invalid email'),
    password: 
      string()
      .required('Required')
      .min(6, 'At least 6 characters')
      .max(24, 'At max 24 characters'),
    confirmPassword: 
      string()
      .required('Required')
      .oneOf([ref('password')], 'Your passwords do not match.'),

    profilepic: 
      mixed().required('Profile picture is required')
      .test("file_size", "The file is too large", (value: any) => {
        if (!value?.size) return true // attachment is optional
        return (value.size/ 1024 / 1024) <= 10
      }),
    name: 
      string()
      .required('Required'),
    age: 
      number()
      .required('Required')
      .typeError('A number is required'),
    language: 
      string()
      .required('Required'),
    location: 
      string()
      .required('Required'),
    gender: 
      number()
      .required('Required'),

    status:   
      number()
      .required('Required')
      .test('check-val', `Validation Error`, (v) => (v === 1 || v === 2)),
  });
}

// smoke: Smoke | '',
// cleanliness: Cleanliness | '',
// pets: Pets | '',
// socialize: Socialize | '',
// cook: Cook | '',
// budget: number | '',
// city: string,
// moveIn: string,
// moveInDate: number | '',
// moveOut: string,
// moveOutDate: number | '',
// occupation: number | '',
// phoneNumber: number | '',
// photoHighlights: FileList | null,

const SeekerPostSchema = () => {
  return object({
    city:
      string()
      .required('Required'),
    budget: 
      number()
      .required('Required'),
    moveIn:
      string()
      .required('Required'),
    moveInDate:
      number()
      .typeError('A number is required')
      .when("moveIn", {
        is: 'later',
        then: (schema: NumberSchema) => schema.required("Date is required").typeError('A number is required'),
        otherwise: (schema: NumberSchema) => schema.typeError('A number is required')
      }),
    moveOut:
      string()
      .required('Required'),
    moveOutDate:
      number()
      .typeError('A number is required')
      .when("moveOut", {
        is: 'later',
        then: (schema: NumberSchema) => schema.required("Date is required").typeError('A number is required'),
        otherwise: (schema: NumberSchema) => schema.typeError('A number is required')
      }),
    phoneNumber:
      string()
      .test("starts_with", "Should start with +49", (val) => val ? val.startsWith('+49') : true)
      .test("correct_length", "Invalid number", (val) => val ? (val.length === 14 || val.length === 13) : true),
    photoHighlights: 
      mixed()
      .test("file_size", "Each file should be less 10MB", (value: any) => {
        if (value?.length > 0) {
          return [...Array(value.length)].every((_: any,i:number) => (value[i].size/ 1024 / 1024) <= 10)
        }
        return true;
      }),
    occupation: 
      string()
      .required('Required'),
    smoke: 
      string().required(),
    pets:
      string().required(),
    cleanliness:
      string().required(),
    socialize:
      string().required(),
    cook:
      string().required(),
    descriptions:
      array().of(string()),
    bio:
      string()
      .test('desc_len_test', 'Should be less than 200 characters', (val) => val ? val.length < 200 : true),
  })
}

const RenterPostSchema = () => {
  return object({
    // location: lazy(value => {
    //   switch (typeof value) {
    //     case 'object':
    //       return object().required('Required'); // schema for object
    //     case 'string':
    //       return string()
    //       .required('Required')
    //       .test('valid_add', 'Invalid Address (Select from dropdown or fill all fields)', (val) => {
    //         try{
    //           console.log(val)
    //           const o = JSON.parse(val)
    //           console.log(o)
    //           return o.address.road && o.address.city && o.address.postcode
    //         }
    //         catch(e){
    //           console.log(e)
    //           return false
    //         }
    //       }); 
          
    //       // schema for string
    //     default:
    //       return string().required('Required'); // here you can decide what is the default
    //   }
    // }),
    location: 
      string()
      .required('Required')
      .test('valid_add', 'Invalid Address (Select from dropdown or fill all fields)', (val) => {
        try{
          console.log(val)
          const o = JSON.parse(val)
          console.log(o)
          return o.address.road && o.address.city && o.address.postcode
        }
        catch(e){
          console.log(e)
          return false
        }
      }),
    title: 
      string()
      .required('Required')
      .typeError('Title is required'),
    rent: 
      number()
      .required('Required')
      .typeError('A number is required'),
    securityDeposit: 
      number()
      .required('Required')
      .typeError('A number is required'),
    roomSize: 
      number()
      .required('Required')
      .typeError('A number is required'),
    roommates: 
      number()
      .required('Required')
      .typeError('A number is required'),

    gender: 
      string()
      .required('Required'),
    age: 
      number()
      .required('Required')
      .min(18)
      .max(40)
      .typeError('A number is required'),

    moveIn:
      string()
      .required('Required'),
    moveInDate:
      number()
      .typeError('A number is required')
      .when("moveIn", {
        is: 'later',
        then: (schema: NumberSchema) => schema.required("Date is required").typeError('A number is required'),
        otherwise: (schema: NumberSchema) => schema.typeError('A number is required')
      }),
    moveOut:
      string()
      .required('Required'),
    moveOutDate:
      number()
      .typeError('A number is required')
      .when("moveOut", {
        is: 'later',
        then: (schema: NumberSchema) => schema.required("Date is required").typeError('A number is required'),
        otherwise: (schema: NumberSchema) => schema.typeError('A number is required')
      }),
    contract: 
      string()
      .required('Required'),

    roommateDetails: 
      array()
      .of(
        object({
          name: string().min(2, 'too short').required('Required'), // these constraints take precedence
          age: number(),
          language: string(),
          gender: string(),
          pfp: mixed().test("file_size", "The file is too large", (value: any) => {
            if (!value?.size) return true // attachment is optional
            return (value.size/ 1024 / 1024) <= 10
          }),
        })
      )
      .test('check-name', `Name of each roommate is required`, (v) => v?.every(i => !!i.name)),

    roomPhotos: 
      mixed().required('Room photos is required')
      .test("file_size", "Each file should be less 10MB", (value: any) => {
        if (value?.length > 0) {
          return [...Array(value.length)].every((_: any,i:number) => (value[i].size/ 1024 / 1024) <= 10)
        }
        return true;
      }),

    extraDetails: array().of(string()),
    extraDescription: string()
    .test('desc_len_test', 'Should be less than 200 characters', (val) => val ? val.length < 200 : true),
    bus_by: string()
    .test('len_test', 'Less than 15 characters', (val) => val ? val.length < 15 : true),
    cc_by: string()
    .test('len_test', 'Less than 15 characters', (val) => val ? val.length < 15 : true),
    uni_by: string()
    .test('len_test', 'Less than 15 characters', (val) => val ? val.length < 15 : true),
    bus_mins: number()
    .test('num_test', 'Cannot be greater than 200', (val) => val ? val < 200 : true),
    cc_mins: number()
    .test('num_test', 'Cannot be greater than 200', (val) => val ? val < 200 : true),
    uni_mins: number() 
    .test('num_test', 'Cannot be greater than 200', (val) => val ? val < 200 : true),

  });
}

const RenterSignupSchema = () => {
  return object({
    user: SignupSchema().required(),
    property: RenterPostSchema().required()
  })
}

const SeekerSignupSchema = () => {
  return object({
    user: SignupSchema().required(),
    tenantData: SeekerPostSchema().required()
  })
}

export const RegisterSchema = (status:number) => {
  if (status === StatusEnum.Renter){
    return RenterSignupSchema();
  }
  else{
    return SeekerSignupSchema();
  }
}