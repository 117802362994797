import { Avatar, Box, Divider, Flex, Grid, GridItem, Spacer, Text, theme } from "@chakra-ui/react";
import { Body0, Caption2, OptionGroup, PrimaryButton, Title1, Title3, Title4 } from "components/molecules";
import { CookingPot, PawPrint, SquareArrowOutUpRight, Users } from "lucide-react";
import { DetailBar } from "../../Dashboard/DetailBar";
import { Cleanliness, Cook, dateFormatter, GenderEnum, getDurationOfStay, Occupation, Pets, Smoke, Socialize } from "utils";
import Location from 'assets/images/LocationIcon.svg';
import Language from 'assets/images/LanguageIcon.svg';
import Education from 'assets/images/EducationIcon.svg';
import Profession from 'assets/images/ProfessionIcon.svg';
import Gender from 'assets/images/GenderIcon.svg';
import GlobeIcon from 'assets/images/GlobeIcon.svg';
import SmokeIcon from 'assets/images/SmokeIcon.svg';
import CleanlinessIcon from 'assets/images/CleanlinessIcon.svg';
import HomeIcon from 'assets/images/HomeIcon.svg';
import { TenantType, User } from "types";
// import { EditModal } from "./EditModals";
import { useState } from "react";
import { countries_map, language_mappings } from "utils/data";
import { EditModal } from "./EditModals";

export const RenterProfileView = ({
  renter
}: {
  renter: User
}) => {

  const { email, name, age, location: currLocation, language, gender, profilepic } = renter;

  const [editModal, setEditModal] = useState<boolean>(false)
  const [editId, setEditId] = useState<string>('')

  const handleOpen = (id: string) => {
    setEditId(id)
    setEditModal(true)
  }

  const handleClose = () => {
    setEditModal(false)
  }

  return(
    <>
			<Flex 
				flexDir={'column'}
				border={'1px solid rgba(216, 220, 224, 1)'}
				borderRadius={'8px'}
				padding={'25px 10px'}
				alignItems={'center'}
			>
				<Avatar size={'xl'} src={`${process.env.REACT_APP_BASE_URL}/${profilepic}`} />
				<Flex 
				marginTop={'20px'}
				marginBottom={'10PX'}
				flexDir={'column'}>
					<Text px={5} alignItems={'left'} textStyle={'book'} >{name}</Text>
					<Text px={5} alignItems={'left'} fontSize={'small'} textStyle={'light'} >{email}</Text>
				</Flex>
				<PrimaryButton px={5} style={{width: '100%',borderRadius: '25px'}}>
					Edit Profile - 60%
				</PrimaryButton>
			</Flex>
      <Flex
      flexDir={'column'}
      border={'1px solid rgba(216, 220, 224, 1)'}
      width={'100%'}
      ml={'10px'}
      borderRadius={'8px'}
      padding={'20px 50px'}
      >
        <Flex justifyContent={'space-between'} width={'100%'} alignItems={'center'} my={5} >
          <Flex flexDir={'row'} alignItems={'baseline'}>
						<Title3>{name}</Title3>
						<Spacer mr={4} />
						<Title4 color='black.800'>age: {age}</Title4>
          </Flex>
        </Flex>

        <Grid width={'100%'} templateRows='repeat(1, 1fr)' templateColumns={[`repeat(1, 1fr)`, `repeat(2, 1fr)`]} gap={5}>
          <DetailBar openModal={() => handleOpen("language")} id={"true"} icon={Language} name="Languages" value={
            language?.split(',').map((l: string) => language_mappings[l]).join(',')
          } />

          <DetailBar openModal={() => handleOpen("countries")} id={"true"} icon={Location} name="Current Location" value={countries_map[currLocation]} />

          <DetailBar openModal={() => handleOpen("gender")} id={"true"} icon={Gender} name="Gender" value={GenderEnum[gender]} />
        </Grid>
        <Divider orientation="horizontal" my={5}/>


        <Divider orientation="horizontal" my={5}/>

        {editModal && <EditModal user={renter} id={editId} isOpen={editModal} handleClose={handleClose} />}
      </Flex>
    </>
  );
}